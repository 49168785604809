import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21d8ce86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-title" }
const _hoisted_2 = { class: "custom-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_loading = _resolveComponent("van-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, {
      title: "查询提交记录",
      "right-text": "提交",
      onClickRight: _ctx.onClickRight
    }, null, 8, ["onClickRight"]),
    _createVNode(_component_van_cell_group, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList, (item) => {
          return (_openBlock(), _createBlock(_component_van_cell, {
            key: item.id,
            "is-link": "",
            center: "",
            to: { path: `/submitRecord/detail/${item.demandNo}` },
            label: '提交时间 ' + item.createdTime
          }, {
            title: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(item.demandNo), 1),
              _createElementVNode("span", _hoisted_2, _toDisplayString(item.demandProduct), 1)
            ]),
            value: _withCtx(() => [
              _createVNode(_component_van_button, {
                type: "primary",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.status[item.status] || '查看详情'), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["to", "label"]))
        }), 128))
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_van_loading, {
      class: "center",
      color: "#13c2c2"
    }, null, 512), [
      [_vShow, _ctx.loading]
    ])
  ], 64))
}