
import { defineComponent, reactive, toRefs } from 'vue'
import demandApi from '@/axios/api/proposal'
import { Proposal } from '@/model/industryManagement/proposal'
import { Toast, Notify } from 'vant'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const pageData = reactive({
      dataList: [] as Proposal[],
      status: {
        '0': '已提交',
        '1': '处理中',
        '2': '处理中',
        '3': '处理中',
        '4': '已完成',
        '5': '已终止',
      },
      loading: false,
    })
    const router = useRouter()
    const onClickRight = () => {
      console.log('跳转到提交页面')
      let path = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
        ? '/demandFormPhone'
        : '/requirementsGathering/demandForm'
      router.push(path)
    }
    pageData.loading = true
    demandApi
      .GetHistory()
      .then((res) => {
        pageData.dataList = []
        if (res) {
          pageData.dataList = res
        } else {
          Toast('未提交任何需求信息')
          router.push('/')
        }
        pageData.loading = false
      })
      .catch((err) => {
        pageData.loading = false
        Notify({ type: 'warning', message: err })
        if (err?.includes('未认证')) {
          router.push('/phoneValidation')
        }
      })
    return {
      ...toRefs(pageData),
      onClickRight,
    }
  },
})
